body {
	background: #1D1F21;
	font-family: 'Roboto Mono', sans-serif;
	color: #C5C8C6;
}

.name {
	color: #eab700;
}

a {
	color: #4271ae;
}

a.resume {
	color: #FFFFFF;
}

a.github {
	color: #FFFFFF;
}

a.linkedin {
	color: #0e76a8;
}

a.twitch {
	color: #6441A4;
}

a.twitter {
	color: #00aced;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

