@media (max-width: 640px) {
    .page {
        padding: 2rem 0rem;
    }
}

@media (min-width: 640px) {
    .page {
        width: 640px;
        margin-left: auto;
        margin-right: auto;
        padding: 2rem 2rem;
    }
}

.row {
    text-align: center;
}